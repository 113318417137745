.table-header {
    background-color: black;
}

.table-header  .MuiTableCell-root{
    color: white !important;
    padding: 8px;
    text-align: center;
    background: black !important;
}

.table-header  .MuiTableRow-root.MuiTableRow-hover:hover{
    color: white !important;
    padding: 8px;
}

.MuiTableSortLabel-root:hover {
    color: white !important;
}

.MuiTableSortLabel-root.Mui-active {
    color: white !important;
}

.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: white !important;
}

.MuiList-root.MuiMenu-list {
    display: flex !important;
    align-items: center;
    flex-direction: column;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    margin-bottom: 5px;
}

.table-body .MuiTableCell-root {
    padding: 25px;
    text-align: center;
}

.cursor-p {
    cursor: pointer;
}

.table-link {
    color: black;
    cursor: pointer;
}


.verticalContent {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.processType {
    background-color: #1fab9f;
    color: white;
}

.kpi-form .MuiTableCell-root {
    border: none;
    padding: 6px;
}

.cursor-pointer {
    cursor: pointer;
}