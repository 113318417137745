@tailwind base;
@tailwind components;
@tailwind utilities;

.full-screen__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  margin: auto;
  outline: 0;
  background-color: rgba(79, 79, 79, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ball {
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  transition: transform 0.15s linear;
}

.checkbox:checked + .label .ball {
  transform: translateX(24px);
}
.checkbox {
  display: none;
}

.inputStyle {
  width: 45px !important;
  height: 45px;
  border-radius: 7px;
  border-width: 1px;
  margin-left: 15px;
  margin-right: 15px;
  background: #eee8e8;
  font-size: 20px;
  text-align: center;
  flex-wrap: wrap;
}


.label {
  position: relative;
  background: hsl(217, 57%, 51%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 5px;
  margin: 0 20px;
  width: 50px;
  height: 26px;
  cursor: pointer;
}

.name {
  font-size: 14px;
  color: hsl(234, 10%, 19%);
}